'use strict';

const baseMenu = require('brand_core/components/menu');
const breakpoints = require('../components/breakpoints');
const debounce = require('lodash/debounce');

const keyboardNav = require('./headerKeyboardNav');
const countrySelectorKeyboardNav = require('brand_core/components/countrySelectorKeyboardNav');
const $sitenav = $('.sitenav');
const $sitenavMenu = $('.sitenav__menu');
const $openTab = $('.js-action__opentab');

/**
 * Opens corresponding content of clicked tab
 * @param {HTMLElement} tab element that was clicked
 */
function openMenuTab(tab) {
    $sitenav.find('.tab-open').removeClass('tab-open'); // close currently open menu tabs
    $(tab).addClass('tab-open');
    $('#' + $(tab).data('tabid')).addClass('tab-open');
}

/**
 * Click event handlers
 */
function clickEvents() {
    $openTab.on('click', function () {
        openMenuTab(this);
    });
}

/**
 * Open the mobile navigation menu
 * @param {string} openDrawerTarget registration or login drawer selector
 */
function openMobileNav(openDrawerTarget) {
    baseMenu.lazyLoadAccountContent(openDrawerTarget);

    // Prevent page scrolling
    $.disableScroll();
    $('html').addClass('menu-active');
    $sitenav.addClass('open');
    $sitenavMenu.addClass('open');
    $(document).trigger('mobileMenu:show');
}

/**
 * Events triggered on screen size
 */
function resizeEvents() {
    let lastWidth = $(window).width();
    const isWidthResized = debounce(function () {
        if ($(window).width() !== lastWidth) {
            lastWidth = $(window).width();
            $(window).trigger('widthResize');
        }
    }, 300);

    const reloadEvents = function () {
        // clear all breakpoint dependent events
        $('*').off('.breakpointDependent');
        baseMenu.mouseEvents();
        baseMenu.miscEvents();

        if (breakpoints.isHighRes()) {
            baseMenu.closeMobileNav();
        }
    };

    // reload events on screen resize
    $(window).on('resize.reloadEvents', isWidthResized);
    $(window).on('widthResize.reloadEvents', reloadEvents);
}

/**
 * Initialize functions
 */
function init() {
    baseMenu.mouseEvents();
    baseMenu.keyboardEvents();
    baseMenu.focusEvents();
    keyboardNav.init();
    countrySelectorKeyboardNav.init();
    baseMenu.desktopTapEvents();
    baseMenu.miscEvents();
    baseMenu.scrollEvents();
    resizeEvents();
    clickEvents();
}

module.exports = function () {
    // baseMenu function overrides
    baseMenu.openMobileNav = openMobileNav;
    // additional functions
    init();
};
