'use strict';
const base = require('brand_core/components/headerKeyboardNav');
const keyboardAccessibility = require('./keyboardAccessibility');

/**
 *  Prev sibling menu item
 * @param {HTMLElement} thisLi element the keyboard event was fired on
 */
function previousSiblingMenuItem(thisLi) {
    let $prevSiblings = $(thisLi).prevAll('li:visible');
    let $focusTarget = $prevSiblings.length ? $prevSiblings.last() : $(thisLi).parent().prev().children('li:visible')
        .last();

    if ($focusTarget.length) {
        $focusTarget.trigger('focus');
    }
}

/**
 *  Next sibling menu item
 * @param {HTMLElement} thisLi element the keyboard event was fired on
 */
function nextSiblingMenuItem(thisLi) {
    let $nextSiblings = $(thisLi).nextAll('li:visible');
    let $focusTarget = $nextSiblings.length ? $nextSiblings.first() : $(thisLi).parent().next().children('li:visible')
        .first();

    if ($focusTarget.length) {
        $focusTarget.trigger('focus');
    } else {
        let $parentLi = $(thisLi).closest('.sitenav-primary__li:not(.sitenav__search)');

        if ($parentLi.find('.sitenav__category-content').length) {  // check for slotted content
            base.findAnchorItem($parentLi);
        }
    }
}

/**
 * Previous levels last tertiary nav item
 * @param {HTMLElement} thisLi element the keyoard event was fired on
 */
function previousTertiaryItem(thisLi) {
    let $focusTarget = $(thisLi).prev().find('.sitenav-tertiary__li:visible').last();

    if ($focusTarget.length) {
        $focusTarget.trigger('focus');
    }
}

/**
 * Previous columns last tertiary nav item
 * @param {HTMLElement} thisLi element the keyoard event was fired on
 */
function previousColumnsTertiaryItem(thisLi) {
    let $focusTarget = $(thisLi).parent().prev().children('li:visible')
        .last()
        .find('.sitenav-tertiary__li')
        .last();

    if ($focusTarget.length) {
        $focusTarget.trigger('focus');
    }
}

/**
 * keyboard events for secondary anchors inside open flyout menu
 */
base.secondaryNav = function () {
    keyboardAccessibility('.sitenav-secondary__li',
        {
            13: function () { // enter
                base.navigateLink(this);
                return true;
            },
            37: function () { // left
                if ($(this).prevAll('li:visible').length) {
                    base.previousMenuItem(this);
                } else {
                    previousSiblingMenuItem(this);
                }
                return true;
            },
            38: function (parentLi) { // up
                let $this = $(this);
                if ($this.prevAll('li:visible').length) {
                    if ($this.prev().find('.sitenav-tertiary__li:visible').length) {
                        previousTertiaryItem(this);
                    } else {
                        base.previousMenuItem(this);
                    }
                } else if ($this.parent().prev().children('li:visible')
                    .last()
                    .find('.sitenav-tertiary__li').length) {
                    previousColumnsTertiaryItem(this);
                } else if ($this.parent().prev().children('li:visible').length) {
                    previousSiblingMenuItem(this);
                } else {
                    base.previousMenuLevel(parentLi);
                }
                return true;
            },
            39: function () { // right
                if ($(this).nextAll('li:visible').length) {
                    base.nextMenuItem(this);
                } else {
                    nextSiblingMenuItem(this);
                }
                return true;
            },
            40: function () { // down
                let $this = $(this);
                if ($this.find('li:visible').length) {
                    base.nextMenuLevel(this, 'li:visible');
                } else if ($this.nextAll('li:visible').length) {
                    base.nextMenuItem(this);
                }
                return true;
            }
        },
        function () {
            return $(this).closest('.sitenav-primary__li');
        }
    );
};

/**
 * keyboard events for tertiary anchors inside open flyout menu
 */
base.tertiaryNav = function () {
    keyboardAccessibility('.sitenav-tertiary__li',
        {
            13: function () { // enter
                base.navigateLink(this);
                return true;
            },
            38: function (parentLi) { // up
                if ($(this).prevAll('li:visible').length) {
                    base.previousMenuItem(this);
                } else {
                    base.previousMenuLevel(parentLi);
                }
                return true;
            },
            40: function (parentLi) { // down
                if ($(this).nextAll('li:visible').length) {
                    base.nextMenuItem(this);
                } else if ($(parentLi).nextAll('li:visible').length) {
                    base.nextMenuItem(parentLi);
                } else if ($(parentLi).parent().next().find('li:visible').length) {
                    nextSiblingMenuItem(parentLi);
                } else {
                    let $primaryLi = $(this).closest('.sitenav-primary__li:not(.sitenav__search)');

                    if ($primaryLi.find('.sitenav__category-content').length) {
                        base.findAnchorItem($primaryLi); // check for slotted content
                    }
                }
                return true;
            }
        },
        function () {
            return $(this).closest('.sitenav-secondary__li');
        }
    );
};

base.init = function () {
    base.skipLinkNav();
    base.primaryNav();
    base.secondaryNav();
    base.tertiaryNav();
    base.sitenavCategoryContent();
};

module.exports = base;
